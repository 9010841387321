<template>
  <v-container fluid>
    <div class="text-right mb-2 mt-5"></div>
    <div class="">
      <v-flex class="py-2 px-3 tableHeader">{{
        $vuetify.lang.t("$vuetify.leaderboard")
      }}</v-flex>

      <v-form
        style="width: 100%"
        class="tableSearch"
        @submit.prevent="fetchList"
      >
        <v-row align="center" class="ma-0">
          <v-col cols="4">
            <v-text-field
              :label="$vuetify.lang.t('$vuetify.search')"
              v-model="search"
              class="mr-2"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  slot="activator"
                  :label="$vuetify.lang.t('$vuetify.date_from')"
                  v-model="search_from"
                  prepend-icon="mdi-calendar-month"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_from"
                no-title
                scrollable
                actions
                autosave
                :allowed-dates="allowedDates"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  slot="activator"
                  :label="$vuetify.lang.t('$vuetify.date_to')"
                  v-model="search_to"
                  prepend-icon="mdi-calendar-month"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_to"
                no-title
                scrollable
                actions
                autosave
                :allowed-dates="allowedDates"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-btn type="submit" outlined block color="secondary">{{
              $vuetify.lang.t("$vuetify.search")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-data-table
      :headers="headers"
      :items="formatedList"
      :loading="loading"
      class="elevation-1 mb-5"
    >
    </v-data-table>
  </v-container>
</template>

<script>
// import { UTC_TO_LOCAL } from "@/helpers";
// import { mapMutations, mapState } from "vuex";
// import { mapMutations } from "vuex";
import moment from "moment";

const search_from = moment().startOf("month").format("YYYY-MM-DD");
const search_to = moment().format("YYYY-MM-DD");

export default {
  name: "leaderboard",
  data() {
    return {
      menu: false,
      menu2: false,
      loading: false,
      items: [],
      search_field: "employ_name",

      search: "",
      search_from,
      search_to,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.name"),
          value: "employ_name",
          align: "left",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.email_sharing"),
          value: "email",
          align: "left",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.social_media_sharing"),
          value: "social_media",
          align: "left",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.interview_scheduled"),
          value: "interview_schedule",
          align: "left",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.job_offered"),
          value: "job_offered",
          align: "left",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.candidates_joined"),
          value: "candidate_joined",
          align: "left",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.total_points"),
          value: "total_points",
          align: "left",
        },
      ];
    },
    formatedList() {
      return this.items.map((item) => {
        const {
          _id: { employ_name },
        } = item;
        return {
          ...item,
          employ_name,
        };
      });
    },
  },
  methods: {
    async fetchList() {
      try {
        this.loading = true;
        const resp = await this.axios.get(
          `/referal-rewards?search_from=${this.search_from}&search_to=${this.search_to}`
        );

        this.items = resp.data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    allowedDates(date) {
      return moment(date).isSameOrBefore(moment(), "day");
    },
  },
  created() {
    this.fetchList();
  },
};
</script>

<style lang="scss" scoped>
.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
}
.tableSearch {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  min-height: 70px;
}
.table-row {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  td {
    color: #2f3242;
  }
}
</style>
